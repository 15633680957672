import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

class RecipeRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: recipes } = data.allMarkdownRemark;

    return (
      <div className="columns is-multiline">
        {recipes &&
          recipes.map(({ node: recipe }) => (
            <div className="is-parent column is-6" key={recipe.id}>
              <article
                className={`blog-list-item tile is-child box notification`}
              >
                <header>
                  {recipe.frontmatter.image ? (
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: recipe.frontmatter.image,
                          alt: `featured image thumbnail for recipes ${recipe.frontmatter.recipename}`,
                        }}
                      />
                    </div>
                  ) : null}
                  <p className="recipe-meta">
                    <Link
                      className="title has-text-primary has-text-weight-bold is-size-5"
                      to={recipe.fields.slug}
                    >
                      {recipe.frontmatter.recipename}
                    </Link>
                    <span
                      className="subtitle is-size-6 is-block"
                      style={{ marginTop: "20px" }}
                    >
                      {recipe.frontmatter.date}
                    </span>
                  </p>
                </header>
                <p style={{ wordBreak: "break-word" }}>
                  {recipe.frontmatter.description}
                  <br />
                  <br />
                </p>
                <Link className="button" to={recipe.fields.slug}>
                  Weiter lesen →
                </Link>
              </article>
            </div>
          ))}
      </div>
    );
  }
}

RecipeRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query RecipeRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "recipe-page" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                recipename
                templateKey
                date(formatString: "MMMM DD, YYYY")
                description
                image {
                  childImageSharp {
                    fluid(maxWidth: 80, maxHeight: 50, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    fixed(height: 80) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <RecipeRoll data={data} count={count} />}
  />
);
