import React from "react";

import Layout from "../../components/Layout";
import RecipeRoll from "../../components/RecipeRoll";
import SEO from "../../components/seo/Seo";
import { StaticQuery, graphql } from "gatsby"; // to query for image data

export default class RecipeIndexPage extends React.Component {
  renderSEO = ({
    file: {
      childImageSharp: { resize: image },
    },
  }) => {
    return (
      <SEO
        title={`Rezeptübersicht`}
        description={`Übersicht aller Rezepte rund um das Thema Fructosintoleranz, Histaminintoleranz, Lactosintoleranz und  Multi-Intoleranz generell`}
        image={image}
        pathname={this.props.location.pathname}
      />
    );
  };

  render() {
    return (
      <Layout>
        <StaticQuery
          render={this.renderSEO}
          query={graphql`
            query {
              file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                  resize(width: 1200) {
                    src
                    height
                    width
                  }
                }
              }
            }
          `}
        />

        <div
          className="full-width-image-container margin-top-0"
          /*  style={{
            backgroundImage: `url('/img/home-jumbotron.jpg')`,
          }} */
        >
          <img
            className="full-width-image margin-top-0"
            style={{
              width: "100vw",
              position: "absolute",
              objectFit: "cover",
              zIndex: "-1",
              left: "0",
            }}
            src="/img/home-jumbotron.jpg"
            loading="lazy"
            alt="different vegetables and fruit on wood"
          />
          <div
            style={{
              display: "flex",
              height: "150px",
              lineHeight: "1",
              justifyContent: "space-around",
              alignItems: "left",
              flexDirection: "column",
            }}
          >
            <h1
              className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
              style={{
                boxShadow: "0.5rem 0 0 #02b7b7, -0.5rem 0 0 #02b7b7",
                backgroundColor: "#02b7b7",
                color: "white",
                padding: "1rem",
              }}
            >
              Multi-intolerant na und?
            </h1>
            <h2
              className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
              style={{
                boxShadow: "#00adb5 0.5rem 0px 0px, #00adb5 -0.5rem 0px 0px",
                backgroundColor: "#00adb5",
                color: "white",
                lineHeight: "1",
                padding: "0.25em",
                textAlign: "center",
              }}
            >
              Rezepte
            </h2>
          </div>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <RecipeRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
